import { useSetDocumentTitle } from 'hooks/useSetDocumentTitle';
import { searchFacetsJob } from 'modules/search/constants/searchFacetsJob';
import { SearchPage } from 'modules/search/pages/Search/SearchPage';

export function SearchJobsPage() {
  useSetDocumentTitle(
    /* TRANSLATORS: job search page title */
    getText('Find Jobs Near You'),
  );

  return (
    <SearchPage searchType="JOB" searchFacets={searchFacetsJob} allowAds />
  );
}

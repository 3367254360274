import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SearchBody } from 'modules/search/components/Body/SearchBody';
import { SearchResultsHeader } from 'modules/search/components/Results/Header/SearchResultsHeader';
import { SearchResultsHits } from 'modules/search/components/Results/Hits/SearchResultsHits';
import { SearchSidebar } from 'modules/search/components/Sidebar/SearchSidebar';
import { useSaveSearch } from 'modules/search/hooks/useSaveSearch';
import { useSearchAnalytics } from 'modules/search/hooks/useSearchAnalytics';
import { useSearchPageActions } from 'modules/search/hooks/useSearchPageActions';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchType } from 'modules/search/types/SearchType';
import {
  useIsSearching,
  useSearchCurrentPageResults,
  useSearchLocation,
  useSearchQuery,
  useSearchSort,
} from 'modules/search/zustand-stores/searchStore';
import { scrollToTop } from 'utils/scroll';
import { scrollIntoView } from 'utils/scrollIntoView';

import { SearchPageLocationState } from './types/SearchPageLocationState';
import { useSearchPageLifecycle } from './useSearchPageLifecycle';

type Props = {
  searchType: SearchType;
  searchFacets: SearchFacet[];
  allowAds: boolean;
};

export function SearchPage({ searchType, searchFacets, allowAds }: Props) {
  const location = useLocation<SearchPageLocationState>();
  const scrollToId = location.state?.scrollToId;

  const { saveSearch, saveableSearch } = useSaveSearch({ searchFacets });
  const { clearSearchUrl, setSearchUrlPageIndex, setSearchUrlSort } =
    useSearchPageActions({ isInSearchLandingPage: false });
  const isSearching = useIsSearching();
  const query = useSearchQuery();
  const searchLocation = useSearchLocation();
  const sort = useSearchSort();

  const { getSearchTrackingData } = useSearchAnalytics({
    searchFrom: location.state?.searchFrom,
  });

  const { initialized } = useSearchPageLifecycle({
    searchType,
    getSearchTrackingData,
    searchFacets,
  });

  const currentResult = useSearchCurrentPageResults();
  const results = initialized ? currentResult : undefined;

  useEffect(() => {
    const hitElement = scrollToId
      ? (document.body.querySelector(
          `[data-hit-id="${scrollToId}"]`,
        ) as HTMLElement | null)
      : undefined;

    if (hitElement) scrollIntoView(hitElement);
    else scrollToTop();
  }, [scrollToId, results]);

  return (
    <SearchBody
      sidebar={
        <SearchSidebar
          initialized={initialized}
          isInSearchLandingPage={false}
          allowAds={allowAds}
          searchFacets={searchFacets}
        />
      }
    >
      <SearchResultsHeader
        results={results}
        isSearching={isSearching}
        query={query}
        searchType={searchType}
        sort={sort}
        saveSearch={saveSearch}
        saveableSearch={saveableSearch}
        onChangeSort={setSearchUrlSort}
        searchFacets={searchFacets}
      />

      <SearchResultsHits
        results={results}
        isSearching={isSearching}
        searchType={searchType}
        searchLocation={searchLocation}
        saveSearch={saveSearch}
        saveableSearch={saveableSearch}
        clearAllFilters={clearSearchUrl}
        getSearchTrackingData={getSearchTrackingData}
        onChangePageIndex={setSearchUrlPageIndex}
        searchFacets={searchFacets}
      />
    </SearchBody>
  );
}

import { useSearchParams } from 'react-router-dom';

import {
  getSearchLocation,
  useJobFamilyFacetData,
} from 'modules/search/zustand-stores/searchStore';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';

export function useSearchReady() {
  const { isEnvironmentLoaded } = useUserEnvironment();

  const initialSearchLocationSet = typeof getSearchLocation() !== 'undefined';

  const [searchParams] = useSearchParams();
  const synonymId = searchParams.get('synonymId');

  const jobFamilyFacetData = useJobFamilyFacetData();
  const jobFamilyFacetLoaded =
    !synonymId || jobFamilyFacetData?.jobSynonym.id === synonymId;

  const mainSearchReady =
    jobFamilyFacetLoaded && isEnvironmentLoaded && initialSearchLocationSet;

  return { mainSearchReady };
}
